import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs/operators';
import { DialogService } from '../../app/services/dialog.service';
import { IHomeScreen } from '../repository/home-screen/home-screen.model';
import { HomeScreenService } from '../services/home-screen.service';
import { DIALOG_SERVICE } from './login.guard';

/**
 * 웹사이트 설정 검사 가드
 * @param key 검사 대상 키
 * @param valueShoudBe 검사 대상의 값이 이 값과 일치할때 이용 가능
 */
export const homeScreenGuard = (
  key: keyof IHomeScreen,
  valueShoudBe = true,
): CanActivateFn => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const homeScreenService = inject(HomeScreenService);
    const dialogServiceType = inject(DIALOG_SERVICE);
    const dialogService: DialogService = inject(dialogServiceType);

    if (homeScreenService.isAvail(key, valueShoudBe)) {
      return true;
    }

    return dialogService.alert('No website setting').pipe(
      map(() => {
        return router.createUrlTree(['/home']);
      }),
    );
  };
};
